import React from 'react'
import './join.css';
import emailjs from '@emailjs/browser'
const join = () => {
    const form = React.useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_wjcr0d6', 'template_pmzwbtr', form.current, 'wP3yFpJPs5PmVF-qc')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    
  return (
    <div className="join" id="joinUs">
        <div className="left-j">
            <hr />
            <div>
                <span className='strock'>READY TO</span>
                <span> LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='strock'> WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container"
            onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your Email' />
                <button className='btn btn-join'>Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default join