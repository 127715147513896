import React from 'react'
import './header.css';
import Logo from '../../../assets/logo.png';
import Bars from '../../../assets/bars.png'
// import Link from 'react-scroll';
const header = props => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menu, setMenu] = React.useState(false)
  return (
    <div className='header'>
      <img src={Logo} alt="" className='logo'/>
      {menu === false && mobile === true ? (
        <div style={{
          backgroundColor: "var(--appColor)", padding:"0.5rem", borderRadius: '5px'
        }}
        onClick= {() => setMenu(true)}
        >
          <img src={Bars} alt="" style={{width: '1.5rem'}} />
        </div>
      ) : (
        <ul className='header-menu'>
          <li onClick={() => setMenu(false)}>
            Home
          </li>
          <li onClick = {() => setMenu(false)}>Programs</li>
          <li onClick = {() => setMenu(false)}>Why us</li>
          <li onClick = {() => setMenu(false)}>Plans</li>
          <li onClick = {() => setMenu(false)}>Testimonials</li>
      </ul>
      )}
        
    </div>
    
  )
}


export default header