import React from "react";
import './program.css';
import {programsData} from '../../data/programsData'
import arrow from '../../assets/rightArrow.png'
const program = () => {
    return(
        <div className="program" id='programs'>
        <div className="program-header">
            <span className='strock'>Explore our</span>
            <span>Programs</span>
            <span className='strock'>to shape you</span>
        </div>
        <div className="program-categories">
            {programsData.map((program) => (
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span><span>{program.details}</span>
                    <div className="join-now"><span>Join Now</span>
                    <img src={arrow} alt="" /></div>
                </div>
            ))}
        </div>
    </div>
    );
};

export default program;