import React from 'react';
import './App.css';
import Hero from './components/hero/hero.jsx';
import Program from './components/programs/program';
import Reason from './components/reasons/reason'
import Plan from './components/plan/plan'
import Testimonial from './components/testimonial/testimonial'
import Join from './components/join/join'
import Footer from './components/footer/footer'
function App() {
  return (
    <div className="App">
       <Hero/>
       <Program/>
       <Reason/>
       <Plan/>
       <Testimonial/>
       <Join/>
       <Footer/>
    </div>
  );
}

export default App;
