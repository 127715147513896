import React from 'react'
import './hero.css';
import Header from './header/header';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter'
function hero() {
    const transition = { type: 'spring', duration: 3 };
    return (
        <div className="hero" id='heros'>
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                <div className="the-best">
                    {/* <motion.div
                        initial={{ left: '220px' }}
                        whileInView={{ left: '8px' }}
                        transition={{ ...transition, type: 'tween' }}
                    ></motion.div> */}
                    <div></div>
                    <span>the best fitness club in town</span>
                </div>

                <div className="hero-text">
                    <div>
                        <span className='strock'>Shape</span><span> Your</span>
                    </div>
                    <div>
                        <span>Ideal body</span>
                    </div>
                    <div>
                        <span>In here we will help you to Shape and build your ideal body and live up your life to fullest.</span>
                    </div>
                </div>
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={140} start={100} delay='4' preFix='+'/>
                        </span>
                        <span>expert coachs</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={978} start={500} delay='1' preFix='+' />
                        </span>
                        <span>membersjooined</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={50} start={10} delay='3' preFix='+' />
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>
                <div className="hero-button">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
            </div>
            <div className="right-h">
                <button className='btn'>Join Now</button>

                <div className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </div>

                <img src={hero_image} alt="" className='hero-image' />
                <img src={hero_image_back} alt="" className='hero-back' />
                <div className="cal">
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories Burned</span><span>220 kcal</span>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default hero