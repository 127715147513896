import React from 'react'
import './footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkedin from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
import Logos from '../../assets/badge.svg'
const footer = () => {
  return (
    <div className="footer-container">
        <hr />
        <div className="footer">
            <div className="social">
                <img src={Github} alt="" />
                <img src={Instagram} alt="" />
                <img src={Linkedin} alt="" />
            </div>
            <div className="logo-f">
                <img src={Logo} alt="" />
                <div>
                <img src={Logos} alt="" />
                <span> Aryan</span>
                </div>
                
            </div>
        </div>
        <div className="blur footer-blur-1"></div>
        <div className="blur footer-blur-2"></div>
    </div>
  )
}

export default footer